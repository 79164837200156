/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: "Proba Pro", sans-serif !important;  }

/* Importing Bootstrap SCSS file. */
@import "/node_modules/bootstrap/scss/bootstrap.scss";

/* Importing Datepicker SCSS file. */
@import "/node_modules/ngx-bootstrap/datepicker/bs-datepicker";


@import "./themes/variables.scss";
@import "./themes/base-css.scss";
@import "./fontastic-styles.css";

@import 'primeng/resources/primeng.css';
@import '@pv-frontend/pv-shared-components/resources/styles/theme.css';
@import '@pv-frontend/pv-shared-components/resources/styles/primeflex.css';
@import '@pv-frontend/pv-shared-components/resources/styles/custom-theme.scss';
@import './assets/variables.css';

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */

 .dot-flashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--brand-color);
    color: var(--brand-color);
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }

  .dot-flashing::before,
  .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .dot-flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--brand-color);
    color: var(--brand-color);
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
  }

  .dot-flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--brand-color);
    color: var(--brand-color);
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dotFlashing {
    0% {
      background-color: var(--brand-color);
    }
    50%,
    100% {
      background-color: #ebe6ff;
    }
  }

  /**
  * ==============================================
  * Dot Flashing
  * ==============================================
  */
  .baggage-popover {
    width: 400px;
    max-width: 400px !important;
  }

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

pv-authentication .text-primary {  
  color: var(--primary-color) !important;
}

.primary-btn {
  background: var(--brand-color);
  border-radius: 4px;
  color: white;
  font-weight: 600;
  padding: 10px 20px;
  border: none;
}