@charset "UTF-8";

@font-face {
  font-family: "untitled-font-3";
  src:url("/assets/fonts/untitled-font-3.eot");
  src:url("/assets/fonts/untitled-font-3.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/untitled-font-3.woff") format("woff"),
    url("/assets/fonts/untitled-font-3.ttf") format("truetype"),
    url("/assets/fonts/untitled-font-3.svg#untitled-font-3") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "untitled-font-3" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "untitled-font-3" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-group-size:before {
  content: "\61";
}
.icon-low-to-high:before {
  content: "\63";
}
.icon-high-to-low:before {
  content: "\64";
}
.icon-filter-icon:before {
  content: "\66";
}
.icon-level:before {
  content: "\68";
}
.icon-right:before {
  content: "\6b";
}
.icon-left-arrow:before {
  content: "\6c";
}
.icon-call:before {
  content: "\6d";
}
.icon-mail:before {
  content: "\6e";
}
.icon-minus-circle:before {
  content: "\71";
}
.icon-gift-card:before {
  content: "\72";
}
.icon-cancel:before {
  content: "\73";
}
.icon-ellipsis-h:before {
  content: "\74";
}
.icon-group:before {
  content: "\76";
}
.icon-flight:before {
  content: "\77";
}
.icon-hotels:before {
  content: "\21";
}
.icon-national-geographic:before {
  content: "\79";
}
.icon-ballon:before {
  content: "\42";
}
.icon-dining:before {
  content: "\45";
}
.icon-events:before {
  content: "\47";
}
.icon-check-circle:before {
  content: "\6f";
}
.icon-check:before {
  content: "\49";
}
.icon-user:before {
  content: "\4a";
}
.icon-users:before {
  content: "\4b";
}
.icon-star:before {
  content: "\4c";
}
.icon-search:before {
  content: "\4d";
}
.icon-clock:before {
  content: "\4e";
}
.icon-bars:before {
  content: "\4f";
}
.icon-minus-circle-1:before {
  content: "\50";
}
.icon-tag:before {
  content: "\52";
}
.icon-list-ol:before {
  content: "\51";
}
.icon-info:before {
  content: "\54";
}
.icon-logout:before {
  content: "\7a";
}
.icon-myaccount:before {
  content: "\41";
}
.icon-down-open-mini:before {
  content: "\55";
}
.icon-up-open-mini:before {
  content: "\56";
}
.icon-map-one:before {
  content: "\31";
}
.icon-bullet:before {
  content: "\32";
}
.icon-angle-right:before {
  content: "\70";
}
.icon-historyicon:before {
  content: "\78";
}
.icon-gifting:before {
  content: "\33";
}
.icon-vector-3:before {
  content: "\59";
}
.icon-bus:before {
  content: "\37";
}
.icon-trains:before {
  content: "\36";
}
.icon-shop-1:before {
  content: "\38";
}
.icon-apple:before {
  content: "\39";
}
.icon-print:before {
  content: "\5a";
}
.icon-marketing:before {
  content: "\30";
}
.icon-partner:before {
  content: "\22";
}
.icon-respect:before {
  content: "\23";
}
.icon-tick:before {
  content: "\24";
}
.icon-cursor:before {
  content: "\25";
}
.icon-warning:before {
  content: "\26";
}
.icon-o:before {
  content: "\27";
}
.icon-cart:before {
  content: "\28";
}
.icon-angle-left:before {
  content: "\29";
}
.icon-bag:before {
  content: "\5e";
}
.icon-car:before {
  content: "\5f";
}
.icon-gift:before {
  content: "\60";
}
.icon-handshake:before {
  content: "\7b";
}
.icon-porter:before {
  content: "\7c";
}
.icon-waiting-room:before {
  content: "\7d";
}
.icon-male:before {
  content: "\5c";
}
.icon-female:before {
  content: "\e000";
}
.icon-circle-o:before {
  content: "\e002";
}
.icon-copy:before {
  content: "\e007";
}
.icon-trash-o:before {
  content: "\e009";
}
.icon-round-plus:before {
  content: "\e00a";
}
.icon-round-minus:before {
  content: "\e00b";
}
.icon-circle-tick:before {
  content: "\e00c";
}
.icon-pencil:before {
  content: "\e00e";
}
.icon-hamburger:before {
  content: "\e00f";
}
.icon-event-org:before {
  content: "\e015";
}
.icon-pharmacy:before {
  content: "\e017";
}
.icon-special-event:before {
  content: "\e018";
}
.icon-paper:before {
  content: "\e019";
}
.icon-health-well:before {
  content: "\e01d";
}
.icon-upload:before {
  content: "\e01e";
}
.icon-exclamation-circle:before {
  content: "\e02b";
}
.icon-th-large:before {
  content: "\e02c";
}
.icon-th-list:before {
  content: "\e030";
}
.icon-cog:before {
  content: "\e031";
}
.icon-settings:before {
  content: "\e032";
}
.icon-x:before {
  content: "\e035";
}
.icon-trash:before {
  content: "\e036";
}
.icon-home-house-streamline:before {
  content: "\e03e";
}
.icon-baggage-with-background:before {
  content: "\e048";
}
.icon-car-with-background:before {
  content: "\e049";
}
.icon-card-1:before {
  content: "\e04a";
}
.icon-close:before {
  content: "\e04b";
}
.icon-flight-1:before {
  content: "\e04c";
}
.icon-food-with-background:before {
  content: "\e04d";
}
.icon-group-2:before {
  content: "\e04e";
}
.icon-hamburger-1:before {
  content: "\e04f";
}
.icon-hotel-with-background:before {
  content: "\e050";
}
.icon-meet-and-greet-with-background:before {
  content: "\e052";
}
.icon-safety:before {
  content: "\e051";
}
.icon-sleeping-pod-with-background:before {
  content: "\e053";
}
.icon-spa-with-background:before {
  content: "\e054";
}
.icon-travel:before {
  content: "\e055";
}
.icon-travel-1:before {
  content: "\e056";
}
.icon-vector-2:before {
  content: "\e03f";
}
.icon-group-1:before {
  content: "\62";
}
.icon-vector-2-1:before {
  content: "\75";
}
.icon-up-arrow:before {
  content: "\65";
}
.icon-hotel:before {
  content: "\67";
}
.icon-car-transfer:before {
  content: "\6a";
}
.icon-spa-voucher:before {
  content: "\43";
}
.icon-meet-and-asist:before {
  content: "\44";
}
.icon-baggage:before {
  content: "\46";
}
.icon-sleeping-pod:before {
  content: "\48";
}
.icon-food-voucher:before {
  content: "\69";
}
.icon-card-2:before {
  content: "\53";
}
