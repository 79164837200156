:root {
  --primary-1: #363534 !important;
  --primary-bg: #F9F9F9 !important;
  --tertiary-bg: #003865 !important;
  --icon-color: #7E7034 !important;
  --bg5: #F6F6F6 !important;
  --bg6: #E2E7EC !important;




  --black1: #303C44 !important;
  --black3: #263238 !important;
  --black6: #1E212C !important;
  --grey1: #303C44 !important;
  --grey2: #59656D !important;
  --gold-bg: #DBCEB2 !important;
  --gold-light-bg: #F2EDE0 !important;
  --yellow1: #FDCE6B !important;
  --yellow2: #BCA36E !important;
  --border2: #ECEEEF !important;
  --green-1: #6FCF97 !important;
  --yellow-1 : #FFD54F !important;
  --black-1: #02184F !important;
  --blue-1: #2F80ED !important;
  --grey: #54565b !important;
  --background: #F6F6F6 !important;


  --input-bg: #F8F9F9 !important;
  --primary-grey: #445261 !important;
  --grey3: #555151 !important;
  --secondary-bg: #990033 !important;
  --border: #E3E5E7 !important;
  --border2: #626569 !important;
  --border3: #EEF0F2 !important;
  --border4: #7F7133 !important;
  --border5: #F2F2F2 !important;
  --brand-color: #827127 !important;
  --black: #000 !important;
  --black2: #2E2E2E !important;
  --black4: #212529 !important;
  --black5: #4B4B4B !important;
  --black6: #7D8E9A !important;
  --black7: #172733 !important;
  --black8: #485473 !important;
  --bg2 : #FCFAF2 !important;
  --bg1: #F1EFE9 !important;
  --bg3: #F1F3F5 !important;
  --bg4: #ECF0F3 !important;
  --bg5: #8BFFF3 !important;
  --bg6: #64707C !important;
  --bg7: #F2F0E6 !important;
  --bg8: #FAFAFA !important;
  --bg9: #E4FFD4 !important;
  --bg10: #FFFAE3 !important;
  --bg11: #E2FFFC !important;
  --bg12: #FAF4E9 !important;
  --bg13: #F2F9FE !important;
  --bg14: #F6F6F9 !important;
  --bg15: #EEEEFF !important;
  --bg16: #F0F7FF !important;
  --bg17: #FAFAFB !important;
  --bg18: #868B91 !important;

  --whitebg: #fff !important;
  --disabled-bg: #B7BEC5 !important;
  --green2: #00C7B2 !important;
  --green3: #024F18 !important;
  --green4: #03BC50 !important;
  --orange1: #D7602D !important;
}
.black1{
  color: var(--black1);
}
.black2{
  color: var(--black2);
}
.grey1{
  color: var(--grey1);
}
.primary-grey{
  color: var(--primary-grey);
}
.brand-btn{
  background-color: var(--brand-color);
  color: #fff !important;
}
.disabled{
  background-color: var(--disabled-bg);
  color: #fff !important;
  opacity: 1;
}
.brand-text{
  color: var(--brand-color) !important;
}
.alert{
  color: #721c24;
  padding: 3px 0 0;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
}

$details-title-color: #084484;
$details-terms-btn-bg: #F1F2F2;
